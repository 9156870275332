/**=====================
    25. Authentication CSS Start
==========================**/

.txt-twitter {
  color: #6fa2d8 !important;
}

.txt-linkedin {
  color: #0077B5 !important; 
}

.txt-fb {
  color: #50598e !important;
}
.login-mega-title{
  font-size: 26px;
  line-height: normal;
  letter-spacing: 0;
  color: #fff;
}

.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-image: url('../../images/login/login_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;
  &:After{
    content: "";
    background: linear-gradient(180deg, #0054BE 0%, #AAD0FF 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
  }
  .logo {
    display: block;
    text-align: center;
    max-width: 275px;
    margin: 0 auto 30px auto;
    text-align: right;
  }

  .btn-showcase {
    .btn {
      line-height: 1;
      padding: 10px 15px;
      margin: 0;

      &+.btn {
        margin-left: 5px;
      }

      svg {
        height: 16px;
        vertical-align: bottom;
      }
    }
  }

  .login-main {
    width: 500px;
    border-radius: 14px;
    padding: 30px;
    // box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: $white;

    .theme-form {
      h2 {
        margin-bottom: 9px;
        text-align: center;
      }
      &__content{
        margin: 0 20px;
      }

  /*     label {
        font-size: 15px;
        letter-spacing: 0.4px;
      } */

      .checkbox label::before {
        background-color: $card-body-color;
        border: 1px solid #dfdfdf;
      }

      .or {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 65%;
          height: 2px;
          background-color: #f3f3ff;
          top: 9px;
          z-index: 0;
          right: 0;
        }
      }

/*       input {
        background-color: #FFFFFF;
        transition: all 0.3s ease;
        box-sizing: border-box;
        height: 38px;
        border: 1px solid #EFEFEF;
        border-radius: 4px;
        &::-webkit-input-placeholder {
          color: $light-text;
        }

        &:hover,
        &:focus {
          border: 1px solid lighten($semi-dark, 35%);
          box-shadow: none !important;
          transition: all 0.3s ease;
        }
      } */

      p {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        color: $color-dark-blue;
        text-align: center;
      }

      .form-group {
        margin-bottom: 10px;
        position: relative;
      }

      .link {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
}

.show-hide {
/*   position: absolute;
  top: 52px;
  right: 20px;
  transform: translateY(-50%); */
  position: absolute;
  top: 39px;
  right: 16px;
  color: $light-gray2;
/*   span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-deafult);

    &.show {
      &:before {
        content: "Göster";
      }
    }

    &:before {
      content: "Gizle";
    }
  } */
}

.needs-validation {
  .invalid-feedback {
    font-size: 80%;
  }

  .show-hide {
    right: 30px;
  }

  .invalid-tooltip {
    top: 10px;
    right: 10px;
    left: unset;
  }
}

/**=====================
    25. Authentication CSS Ends
==========================**/