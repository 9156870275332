/**=====================
    57. breadcrumb CSS Start
==========================**/
.breadcrumb-colored{
	li{
		a{
			color: $breadcrumb-ancher-color;
		}
		&.active{
			color: $breadcrumb-ancher-color;
			opacity:0.7;
		}
	}
	.breadcrumb-item{
		&:before{
			color: $breadcrumb-ancher-color;
		}
	}
	.breadcrumb-item.txt-dark{
		&:before{
			color: $breadcrumb-ancher-dark-color;
		}
	}
}
.breadcrumb-item{
	font-size: 14px;
	letter-spacing: 0;
	line-height: 20px;
	color: $primary-color;
	&.active{
		text-transform: capitalize;
		color: $breadcrumb-color;
	}
}
/**=====================
    57. breadcrumb CSS ends
==========================**/
