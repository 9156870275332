.document {
  padding: 24px;
  border-radius: 14px;
  background-color: $white;
  box-shadow: $card-box-shadow;
  max-width: 287px;
  &__title {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    display: block;
  }
  &__size {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }
}
