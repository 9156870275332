/**=====================
   55. theme layout CSS Start
==========================**/

// .page-wrapper.compact-wrapper, .page-wrapper.horizontal-wrapper {
// 	.page-body-wrapper {
// 		.sidebar-wrapper{
// 			&[sidebar-layout="border-sidebar"] {
// 				.sidebar-main {
// 					.sidebar-links{
// 						>li{
// 							.sidebar-link{
// 								border-bottom: 1px solid rgba($primary-color, 0.1);
// 								transition: all 0.3s ease;
// 							}
// 						}
// 					}
// 				}
// 			}
// 			&[sidebar-layout="iconcolor-sidebar"]{
// 				.sidebar-main{
// 					.sidebar-links{
// 						>li{
// 							$colors: $success-color, $secondary-color, $primary-color, $danger-color, $info-color, $warning-color, $light-color;
// 							$repeat: 60 ;
// 							@for $i from 1 through $repeat {
// 								&:nth-child(#{length($colors)}n+#{$i}) {
// 									svg{
// 										color: lighten(nth($colors, random(length($colors))), 15%) !important;
// 									}
// 									&:hover{
// 										a{
// 											svg{
// 												stroke: lighten(nth($colors, random(length($colors))), 15%) !important;
// 											}
// 											&:hover{
// 												background-color: rgba(nth($colors, random(length($colors))), 0.15) !important;
// 											}
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

/**=====================
   55. theme layout CSS ends
==========================**/
