body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.provizyon-form {
  margin-bottom: 15px;
  width: 800px;
  zoom: 1.3 !important;
  margin: 0 auto;
  &__header {
    height: 85px;
    padding: 0 30px;
    &__title {
      text-align: center;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      color: $yazdir-form-color;
      margin: 0;
    }
    span {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 13px;
      color: $yazdir-form-color;
    }
  }
  &__header-bar {
    height: 48px;
    background: $yazdir-form-color;
    padding: 0 30px;
    p {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      color: $white;
      margin: 0;
    }
    @media print {
      &__print-button {
        display: none !important;
      }
      // &__title {
      //   border: 1px solid $light-gray3;
      //   padding: 15px;
      //   color: black !important;
      //   width: 100% !important;
      //   p {
      //     color: black !important;
      //   }
      // }
    }
    &__print-button {
      background: none;
      border: 0;
      outline: none;
    }
  }
  &__content {
    padding: 0 30px;
    p {
      color: $color-dark-blue;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      margin: 1rem 0;
    }
    p + &__title {
      color: $yazdir-form-color;
      margin-bottom: 5px;
    }
    table {
      &.table-top {
        td {
          padding: 4px 10px;
          border: 1px solid $light-gray3;
          .row-title {
            color: $color-dark-blue;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 15px;
            display: inline-block;
            span {
              text-align: right;
            }
          }
          .row-text {
            color: $color-dark-blue;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 15px;
            padding-left: 5px;
          }
        }
      }
      &.table-bottom {
        td,
        th {
          padding: 4px 10px;
          border: 1px solid $light-gray3;
        }
        th {
          color: $color-dark-blue;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 11px;
          background: $light-semi-gray;
        }
        td {
          color: $color-dark-blue;
          font-size: 10px;
          letter-spacing: 0;
          line-height: 14px;
        }
      }
      &.table-toplam {
        td,
        th {
          padding: 4px 10px;
          border: 1px solid $light-gray3;
          background: $light-semi-gray;
        }
        td {
          color: $yazdir-form-color;
          font-size: 10px;
          letter-spacing: 0;
          line-height: 14px;
        }
      }
    }
  }
}
.td-padding-5 {
  td,
  th {
    padding: 4px 5px !important;
  }
}
.yazdir-card {
  height: 77px;
  border: 1px solid $light-gray3;
  &.imza {
    height: 96px;
    p {
      color: $color-dark-blue;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
  span {
    color: $color-dark-blue;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 11px;
    padding: 5px;
  }
  p {
    color: $color-dark-blue;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 11px;
    padding: 5px;
    font-weight: 400;
    margin: 0;
  }
  &__bottom {
    p {
      color: $color-dark-blue;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 10px;
      padding: 7px 16px;
      margin: 0;
    }
    span {
      color: $color-dark-blue;
      font-size: 8px;
      letter-spacing: 0;
      line-height: 11px;
      padding: 0 16px;
      margin: 0;
      display: block;
      font-weight: 400;
    }
  }
}
