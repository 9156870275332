.message-box {
  max-height: 302px;
  border: 1px solid $light-semi-gray;
  overflow-y: auto;
  &.empty {
    height: 120px;
    background: $light-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    .empty-text {
      color: $brown;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 120px;
    &:nth-child(odd) {
      background: $light-gray2;
    }
    &:nth-child(even) {
      background: $white;
    }
    &__left {
      display: flex;
      align-items: center;
      &__case {
        padding-right: 15px;
        i {
          color: $gray;
        }
      }
      &__content {
        &__title {
          color: $color-dark-blue;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 17px;
          margin-bottom: 10px;
          margin-top: 5px;
        }
        &__desc {
          color: $color-dark-blue;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 22px;
          margin-bottom: 10px;
        }
        &__info {
          color: $color-dark-blue;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 16px;
          margin-bottom: 10px;
        }
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__top {
        &__date {
          color: $color-dark-blue;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 16px;
          text-align: right;
        }
        &__reader {
          color: $color-dark-blue;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 13px;
          text-align: right;
        }
      }
    }
    &.active {
      background: $light-gray;
      .message-box__item__left {
        &__case {
          i {
            color: $link-color;
          }
        }
      }
    }
  }
}
