.user-info {
  display: flex;
  .card {
    padding: 8px;
    min-width: 350px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 2px 11px 0 #f7f7f8;
  }
}

.user-alt-info {
  background-color: #f6f6f7;
  padding: 20px;
  border-radius: 12px;
  height: 90px;
}

.provizyon-bilgileri {
  display: flex;
  align-items: center;
}

.user-alt-info-orange {
  height: 90px;
  border-radius: 6px;
  background-color: #f47421;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 20px;
  }
}
.user-alt-info-vip {
  height: 90px;
  border-radius: 6px;
  background-color: #dc3545;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  .alt-text {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .alt-text2 {
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
  }
  .alt-text3 {
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    float: left;
  }
}
.user-title-name {
  color: #001e3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.user-alt-name {
  color: #001e3c;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.shy-sg-kli {
  color: #ed6b06;

  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.vakifli {
  color: #005092;

  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.left-text {
  color: #001e3c;

  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.right-text {
  color: #001e3c;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.odenecek-tutar {
  color: #51bb25;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.red-tutar {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.tutarlar {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.odenecek-tutar-bg {
  border-radius: 5px;
  background-color: #f6f6f7;
  padding: 8px;
  height: 36px;
}

.mustehak-info {
  border-radius: 5px;
  height: 50px;
  background-color: #51bb25;
  box-shadow: 0 2px 11px 0 #f7f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
}
