$fa-font-path: "../../../fonts/fa" !default;

.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("#{$fa-font-path}/fa-light-300.eot");
  src: url("#{$fa-font-path}/fa-light-300.eot?#iefix")
      format("embedded-opentype"),
    url("#{$fa-font-path}/fa-light-300.woff2") format("woff2"),
    url("#{$fa-font-path}/fa-light-300.woff") format("woff"),
    url("#{$fa-font-path}/fa-light-300.ttf") format("truetype"),
    url("#{$fa-font-path}/fa-light-300.svg#fontawesome") format("svg");
}

.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("#{$fa-font-path}/fa-regular-400.eot");
  src: url("#{$fa-font-path}/fa-regular-400.eot?#iefix")
      format("embedded-opentype"),
    url("#{$fa-font-path}/fa-regular-400.woff2") format("woff2"),
    url("#{$fa-font-path}/fa-regular-400.woff") format("woff"),
    url("#{$fa-font-path}/fa-regular-400.ttf") format("truetype"),
    url("#{$fa-font-path}/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("#{$fa-font-path}/fa-solid-900.eot");
  src: url("#{$fa-font-path}/fa-solid-900.eot?#iefix")
      format("embedded-opentype"),
    url("#{$fa-font-path}/fa-solid-900.woff2") format("woff2"),
    url("#{$fa-font-path}/fa-solid-900.woff") format("woff"),
    url("#{$fa-font-path}/fa-solid-900.ttf") format("truetype"),
    url("#{$fa-font-path}/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}
