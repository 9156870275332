
.custom-accordion2{
    .accordion{
        > .card{
            padding: 0;
            .card-header{
                padding: 0;
                margin: 0;
                border: 0;
                button{
                    padding: 22px 20px;
                    width: 100%;
                    text-align: left;
                    height: 106px;
                    background: $light-semi-gray;
                    border-radius: 12px;
                    .fa-chevron-down{
                        &::before{
                            font-size: 24px;
                            color: $primary-color;
                            display: block;
                        }
                    }
                    &[aria-expanded="true"]{
                        margin: 9px;
                        width: calc(100% - 18px);
                        .fa-chevron-down{
                            &::before{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}