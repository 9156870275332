.tooltip {
  .tooltip-inner {
    font-family: $font-source-sans;
    min-width: 220px;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    padding: 10px;
    text-align: center;
    background: $aqua;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

[placement="right"] {
  + .tooltip {
    div {
      &::before {
        border-right-color: $aqua !important;
      }
    }
  }
}
[placement="top"] {
  + .tooltip {
    div {
      &::before {
        border-top-color: $aqua !important;
      }
    }
  }
}
[placement="left"] {
  + .tooltip {
    div {
      &::before {
        border-left-color: $aqua !important;
      }
    }
  }
}
[placement="bottom"] {
  + .tooltip {
    div {
      &::before {
        border-bottom-color: $aqua !important;
      }
    }
  }
}

.warning-tooltip-icon {
  color: $orange;

  font-size: 16px;
  & + .tooltip {
    .tooltip-inner {
      background: $orange;
    }
  }
  &[placement="right"] {
    + .tooltip {
      div {
        &::before {
          border-right-color: $orange !important;
        }
      }
    }
  }
  &[placement="top"] {
    + .tooltip {
      div {
        &::before {
          border-top-color: $orange !important;
        }
      }
    }
  }
  &[placement="left"] {
    + .tooltip {
      div {
        &::before {
          border-left-color: $orange !important;
        }
      }
    }
  }
  &[placement="bottom"] {
    + .tooltip {
      div {
        &::before {
          border-bottom-color: $orange !important;
        }
      }
    }
  }
}
