.dis {
  margin: auto;
  hr {
    height: 2px;
    border: 0;
    background: $light-gray;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__top-left {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
    padding-right: 20px;
  }
  &__top-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
    padding-left: 20px;
    border-left: 2px solid $light-gray;
    border-radius: 2px;
  }
  &__bottom-left {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    padding-right: 20px;
  }
  &__bottom-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    padding-left: 20px;
    border-left: 2px solid $light-gray;
    border-radius: 2px;
  }
  .dis-item {
    svg {
      g {
        transform-origin: 0 !important;
      }
    }

    &:hover {
      cursor: pointer;
      svg {
        g {
          fill: rgba(152, 218, 118, 0.3);
        }
      }
    }
    &.active {
      svg {
        g {
          fill: $light-green;
        }
      }
    }
  }
}
