/**=====================
    24. According CSS start
==========================**/
.accordion {
  & > .card {
    box-shadow: none;
    border: $card-border-color !important;
    margin-bottom: 10px !important;
    .card-header {
      margin: -1px;
      padding: 20px;
      .btn {
        padding: 0;
      }
      .btn-link {
        color: $theme-body-font-color;
        font-weight: 500;
        &:hover,
        &:focus {
          text-decoration: none;
          .custom-accordion {
            .accordion {
              > .card {
                padding: 0;
                .card-header {
                  padding: 0;
                  margin: 0;
                  border: 0;
                  button {
                    padding: 30px;
                    width: 100%;
                    text-align: left;
                    height: 106px;
                    .fa-chevron-down {
                      &::before {
                        font-size: 24px;
                        color: $primary-color;
                        display: block;
                      }
                    }
                    &[aria-expanded="true"] {
                      .fa-chevron-down {
                        &::before {
                          transform: rotate(180deg);
                        }
                      }
                    }
                  }
                }
                .card-body {
                  padding: 0;
                  &__content {
                    padding: 30px 90px;
                    background: $light-gray;
                  }
                  &__bottom {
                    margin: 20px;
                    border-radius: 5px;
                    background-color: $warning-color;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 12px;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
.default-according {
  .card {
    margin-bottom: 0;
    box-shadow: none;
    & + .card {
      margin-top: $according-card-top-margin;
    }
    .btn-link {
      font-weight: $according-btn-weight;
      color: $theme-body-font-color;
      text-decoration: none;
    }
    .btn-link {
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
    .card-header {
      padding: $according-card-header-padding;
      border: $card-border-color;
      i {
        position: absolute;
        left: 18px;
        font-size: 20px;
        top: 20px;
      }
      h5 {
        margin-top: 2px;
      }
    }
    .card-body {
      border: $card-border-color;
      border-top: none;
    }
  }
}
.default-according.style-1 {
  button {
    width: 100%;
    text-align: left;
    &:before {
      right: 20px;
      position: absolute;
      transition: 0.4s;
    }
    &[aria-expanded="true"] {
      &:before {
        content: $according-open-icon;
        font-family: $according-card-header-icon;
      }
    }
    &[aria-expanded="false"] {
      &:before {
        content: $according-close-icon;
        font-family: $according-card-header-icon;
      }
    }
  }
}
/**=====================
     24. According CSS Ends
==========================**/
