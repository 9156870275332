@media screen and (min-width: 1240px) {
  .modal-dialog {
    //max-width: 1200px;
  }
}

.modal-content {
  padding: 30px;
  .modal-header {
    border-bottom: 0;
    padding: 0;
    align-items: center;

    .modal-title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }
    .close {
      padding: 0;
      margin: 0;
      opacity: 1;
    }
    .fa-times {
      color: $color-dark-blue;
    }
  }
  .modal-body {
    padding: 0;
  }
}
