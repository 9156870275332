.mat-form-field {
  font-size: $form-placeholder-font-size;
  color: $form-input-text-color;
  font-family: $font-source-sans, $font-serif;
  line-height: $body-font-line-height;
  letter-spacing: 0;
  display: block !important;
  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-flex {
      border: 1px solid;
      border-color: $form-input-border-color;
      background-color: $form-input-bg-color;
      border-radius: $form-input-border-radius;
      height: 38px;
      padding: 0;
      position: relative;
      .mat-form-field-infix {
        border-top: 0;
        padding: 0;
        input {
          animation: none !important;
          height: 38px;
          padding: 0 12px;
          box-sizing: border-box !important;
          border-radius: $form-input-border-radius;
          &:disabled {
            background: $light-semi-gray;
            cursor: not-allowed;
            &::-webkit-input-placeholder {
              color: $form-input-text-color;
            }
          }
          &::-webkit-input-placeholder {
            color: $form-placeholder-color;
            font-size: $form-placeholder-font-size;
            letter-spacing: $form-placeholder-letter-spacing;
            font-weight: $form-placeholder-font-weight;
            line-height: $form-placeholder-line-height;
          }

          &:focus {
            box-shadow: 0 0 6px 0 $primary-color;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
            -webkit-text-fill-color: $form-placeholder-color !important;
          }
        }
      }
      .mat-form-field-suffix {
        position: absolute !important;
        right: 0;
        .mat-icon-button {
          width: 38px !important;
          height: 38px !important;
          font-size: 12px;
          color: $color-dark-blue;
          right: 3px;
        }
      }
    }
    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-calendar {
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 30, 60, 0.1);
  height: 300px !important;
  width: 240px !important;
  font-family: $font-source-sans;
  .cdk-focused {
    .mat-calendar-body-active {
      .mat-calendar-body-cell-content {
        border-color: $gray !important;
        background-color: $transparent-color !important;
      }
    }
  }
  .mat-calendar-table-header {
    th {
      color: $gray;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
    }
  }
  .mat-calendar-body-cell-container {
    color: $color-dark-blue;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    background-color: $transparent-color;
    .mat-calendar-body-cell {
      &:hover {
        .mat-calendar-body-cell-content {
          background-color: $primary-color !important;
          color: $white !important;
        }
      }
      .mat-calendar-body-cell-content {
        width: 75% !important;
        height: 75% !important;
        &.mat-calendar-body-selected {
          background-color: $primary-color !important;
          color: $white !important;
        }
        &.mat-calendar-body-today,
        &.mat-calendar-body-active {
          border-color: $gray !important;
          background-color: $transparent-color !important;
        }
      }
    }
  }
}
