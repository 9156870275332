.cc-wrapper {
  display: flex;
}
.cc {
  background-color: #ffffff;
  width: 287px;
  margin-bottom: 30px;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  &__top {
    padding: 15px 15px 15px 23px;
    display: flex;
    align-items: center;
    border: 1px dashed #004296;
    border-radius: 14px 14px 0 0;
    &__icon {
      width: 48px;
      height: 48px;
      background-color: blue;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
    &__content {
      padding-left: 23px;
      flex: 1;
      &__title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        color: $color-dark-blue;
        margin-bottom: 8px;
      }
      &__mega-text {
        font-size: 42px;
        line-height: 30px;
        display: block;
        font-weight: 600;
      }
    }
  }
  &__bottom {
    &__button {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 15px 15px;
      background-color: #004092;
      color: white !important;
    }
  }
}
