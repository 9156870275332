.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  margin: 0;
  margin-right: 10px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $danger-color;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
  }
  &.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}
input {
  &:checked {
    & + .slider {
      background-color: $success-color;
      &:before {
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
      }
    }
  }
  &:focus {
    & + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
  }
}
