.sidebar-accordion {
  .accordion {
    > .card {
      padding: 0;
      border: 0 !important;
      border-bottom: 2px solid $light-gray !important;
      border-radius: 0;
      .card-body {
        padding: 0 24px 16px 24px;
        p {
          color: $table-color;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
      .card-header {
        padding: 0;
        margin: 0;
        border: 0;
        button {
          padding: 0 24px;
          width: 100%;
          text-align: left;
          height: 50px;
          background: $white;
          border-radius: 0;
          h4 {
            margin: 0;
          }
          .fa-chevron-down {
            &::before {
              font-size: 14px;
              color: $primary-color;
              display: block;
            }
          }
          &[aria-expanded="true"] {
            .fa-chevron-down {
              &::before {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}
