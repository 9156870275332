
.custom-accordion{
    .accordion{
        > .card{
            padding: 0;
            .card-header{
                padding: 0;
                margin: 0;
                border: 0;
                button{
                    padding: 30px;
                    width: 100%;
                    text-align: left;
                    height: 106px;
                    .fa-chevron-down{
                        &::before{
                            font-size: 24px;
                            color: $primary-color;
                            display: block;
                        }
                    }
                    &[aria-expanded="true"]{
                        .fa-chevron-down{
                            &::before{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}