.pagination {
  .page-item {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    .page-link {
      padding: 0;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid $light-gray;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      color: $color-dark-blue !important;
    }
    &.active,
    &:hover {
      .page-link {
        background: $link-color !important;
        border-color: $link-color !important;
        color: $white !important;
      }
    }
  }
}
.pagination-select {
  width: 60px;
  padding: 5px 10px !important;
  font-size: 14px !important;
  line-height: 14px !important;
  background-position: right 10px center !important;
}
