#toast-container {
  .ngx-toastr {
    border-radius: 5px;
    background-color: $white !important;
    box-shadow: 2px 2px 11px 0 $gray !important;
    min-height: 78px;
    padding-right: 40px !important;
    min-width: 400px;
    padding: 15px 15px 15px 65px;
    background-image: none !important;
    &::before {
      position: absolute;
      padding: 7px;
      border-radius: 50%;
      top: 24px;
      left: 24px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
    &::after {
      content: "";
      position: absolute;
      height: calc(100% - 16px);
      width: 4px;
      left: 8px;
      top: 8px;
      border-radius: 2px;
    }
    &.toast-success {
      &::before,
      &::after {
        background: $green;
      }
      &::before {
        font-family: $font-awesome;
        content: "\f00c";
      }
    }
    &.toast-error {
      &::before,
      &::after {
        background: $danger-color;
      }
      &::before {
        font-family: $font-awesome;
        content: "\f00d";
      }
    }
    &.toast-warning {
      &::before,
      &::after {
        background: $yellow;
      }
      &::before {
        font-family: $font-awesome;
        content: "\f12a";
      }
    }
    &:hover {
      box-shadow: 2px 2px 11px 0 $gray !important;
    }
    .toast-title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      color: $color-dark-blue;
      margin-bottom: 6px;
    }
    .toast-message {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      color: $color-dark-blue;
    }
    .toast-close-button {
      position: absolute;
      right: 15px;
      color: $color-dark-blue;
      top: 0;
      height: 100%;
      font-size: 24px;
    }
  }
}
