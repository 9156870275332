/**=====================
     39. Table  CSS Start
==========================**/
.pills-component {
  width: 20%;
}
.table-counter {
  width: 24px;
  height: 24px;
  color: $brown;
  background: $light-yellow;
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-weight: 400;
  line-height: 20px;
}

.table-delete-items {
  display: inline-block;
  margin-top: 30px;
  &__content {
    float: right;
    color: $danger-color;
    cursor: pointer;
    font-weight: 600;
    .fas,
    .far,
    .fa,
    .fab {
      font-size: 18px;
    }
  }
}

.table {
  margin-bottom: $table-b-margin;
  border: 1px solid $light-semi-gray;
  overflow-x: scroll;
  white-space: nowrap;
  th,
  td {
    padding: $table-padding;
  }
  th {
    color: $table-heading-color;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  }
  td {
    color: $table-color;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 20px;
  }
  &.table-optik {
    thead {
      tr {
        text-align: center;
      }
      th {
        font-weight: 600;
        line-height: 20px;
      }
    }
    tbody {
      td {
        padding: 8px 30px;
        vertical-align: middle;
      }
    }
  }

  &.table-optik-list {
    td,
    th {
      border: 1px solid $light-gray3;
    }
    th {
      border-bottom: 0;
    }
    thead {
    }
    tbody {
      tr {
        background-color: $light-gray2;
      }
      td {
        vertical-align: middle;
        padding: 5px 30px;
      }
    }
  }
  tbody.empty {
    height: 50px;
    td {
      vertical-align: middle;
      text-align: center;
      background: $light-yellow;
      color: $brown;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
  [class*="bg-"] {
    color: $white;
  }
  .bg-light {
    color: $theme-body-font-color;
  }
  tfoot {
    font-weight: $table-footer-font-weight;
  }
  .Double {
    th,
    td {
      border-bottom-style: double;
      border-color: $light-semi-gray;
    }
    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }
  thead {
    .Double {
      th {
        border-top: 1px double $light-semi-gray;
        border-bottom-style: double !important;
        border-color: $light-gray !important;
      }
    }
  }
  .Dotted {
    th,
    td {
      border-bottom-style: dotted;
      border-color: $light-semi-gray;
    }
    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }
  thead {
    .Dotted {
      th {
        border-top: 1px dotted $light-semi-gray;
        border-bottom-style: dotted !important;
        border-color: $light-gray !important;
      }
    }
  }
  .Dashed {
    th,
    td {
      border-bottom-style: dashed;
      border-color: $light-semi-gray;
    }
    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }
  thead {
    .Dashed {
      th {
        border-top: 1px dashed $light-semi-gray;
        border-bottom-style: dashed !important;
        border-color: $light-gray !important;
      }
    }
  }
  .fa-plus {
    color: $green;
    cursor: pointer;
  }
  .fa-pen {
    color: $primary-color;
    cursor: pointer;
  }
  .fa-trash-alt {
    color: $danger-color;
    cursor: pointer;
  }
  .fas,
  .far,
  .fa,
  .fab {
    font-size: 18px;
  }
}
.table-inverse {
  color: $white;
  background-color: $table-inverse-bg-color;
  th,
  td {
    border-color: $white;
    color: $white;
  }
  &.table-bordered {
    border: 0;
  }
}
.table[class*="bg-"] {
  th,
  td {
    color: $white;
  }
}
.table-hover {
  tbody {
    tr:hover {
      background-color: $table-hover-color;
    }
  }
}

//Sizing class
.table-xl {
  td,
  th {
    padding: $table-xl-padding;
    font-size: $table-xl-font;
  }
}
.table-lg {
  td,
  th {
    padding: $table-lg-padding;
    font-size: $table-lg-font;
  }
}
.table-de {
  td,
  th {
    padding: $table-de-padding;
  }
}
.table-sm {
  th,
  td {
    padding: $table-sm-padding;
    font-size: $table-sm-font;
  }
}
.table-xs {
  th,
  td {
    padding: $table-xs-padding;
    font-size: $table-xs-font;
  }
}

//Table Border
.table-border-horizontal {
  tr,
  th,
  td {
    border-top: 1px solid $horizontal-border-color;
    padding: $horizontal-padding;
  }
}
.table-border-vertical {
  tr,
  th,
  td {
    border-top: 0;
    border-right: 1px solid $horizontal-border-color;
  }
  tr {
    td {
      &:last-child {
        border-right: none;
      }
    }
  }
}
.table-bordernone {
  td {
    border: none !important;
  }
  thead {
    th {
      border: none !important;
    }
  }
  .bd-t-none {
    border-top: none;
  }
  .u-s-tb {
    padding: 11px;
  }
}
.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: $light-gray2;
      }
    }
  }
  td,
  th {
    border: 0 !important;
  }
}
.table-bordered {
  td,
  th {
    border-color: $horizontal-border-color;
  }
}
/**=====================
     39.Table CSS Ends
==========================**/
